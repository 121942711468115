<template>
  <b-modal
    id="add-user-modal"
    ref="add-user-modal"
    title="Thêm App users"
    @show="openStaffPopup"
    @hidden="resetModal"
    hide-footer
    @ok="submit"
  >
    <b-container fluid class="p-0">
      <b-row>
        <b-col>
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input
              @keypress.enter="handleAdd"
              v-model="searchInput"
              id="inline-form-input-username"
              placeholder="Username"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click.stop="handleAdd">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/search.svg" />
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-overlay :show="loading">
            <template-table
              :column="column"
              :data="data"
              :paging="paging"
              :tableAction="false"
              :selectAction="false"
              :searchAction="false"
              @search="searchRequest"
              @reset="resetRequest"
              :pagingAction="false"
            >
              <template v-slot:body="{ item }">
                <td>
                  <div class="d-flex align-items-center">
                    <avatar
                      size="40px"
                      :text="item.fullName"
                      :src="item.avatar && item.avatar.url"
                      :rounded="true"
                    ></avatar>
                    <div class="d-flex flex-column ml-5">
                      <p
                        class="mb-0"
                        style="
                          font-weight: 600;
                          font-size: 13px;
                          color: #515356;
                        "
                      >
                        {{ item.fullName }}
                      </p>
                      <p
                        class="mt-2 mb-0"
                        style="font-size: 12px; color: #888c9f"
                      >
                        Mã số {{ item.code }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <b-button
                    variant="link"
                    @click.stop="handleAddMore(item)"
                    :disabled="isDuplicate(chosen, item)"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/plus.svg"
                      /> </span
                  ></b-button>
                </td>
              </template>
            </template-table>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalAddUser',
  props: {
    patientChoosens: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      column: [],
      data: [],
      paging: {},
      searchInput: null,
      loading: false,
    };
  },
  computed: {
    chosen() {
      return this.patientChoosens;
    },
  },

  methods: {
    isDuplicate(array, item) {
      return array.some((arr) => arr.id === item.id);
    },
    handleAdd() {
      this.openStaffPopup();
    },
    searchRequest() {},
    async openStaffPopup() {
      try {
        this.loading = true;
        let { data } = await this.$api.get('/Patient', {
          params: { name: this.searchInput },
        });
        this.data = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    handleAddMore(payload) {
      this.$emit('updatePatient', payload);
    },
    resetModal() {},
    submit() {},
    resetRequest() {},
  },
};
</script>

<style></style>
